<template>
  <div class="box">
    <div class="sidebar">
      <sidebar></sidebar>
    </div>
    <div class="pay" v-show="paystatus == 1">
      <Header></Header>
      <Menu></Menu>
      <div class="pay_cont">
        <div class="title">贵单位缴费标准</div>
        <div class="but_list">
          <div class="but">
            <el-select
              v-model="form.year"
              placeholder="缴费年度"
              style="margin-right: 10px"
              @change="yearchange"
            >
              <el-option
                v-for="(item, index) in year"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
            <!-- <el-button
              @click="checked = !checked"
              :class="checked ? 'el-button2' : ''"
              >{{ isfullyear == 1 ? payprice / 1 : payprice / 2 }}元/{{
                isfullyear == 1 ? "年" : "半年"
              }}</el-button> -->
              <el-button
              @click="checked = !checked"
              :class="checked ? 'el-button2' : ''"
              >{{payprice / 1}}元/年</el-button>
            <img src="../assets/wbanner_4.png" alt="" />
          </div>
        </div>
        <div class="pay_voucher">缴费凭证</div>
        <el-upload
          action="https://www.ic-ceca.org.cn/api/common/upload"
          :headers="{ token: token }"
          :on-success="handleAvatarSuccess"
        >
          <div class="upload_voucher">
            <img src="../assets/wicon_7.png" alt="" />上传
          </div>
        </el-upload>
        <div class="word">对公支付</div>
        <div class="text">账户：{{ payinfo.账户 }}</div>
        <div class="text">开户行：{{ payinfo.开户行 }}</div>
        <div class="text">账号：{{ payinfo.账号 }}</div>
        <div class="text">统一社会信用代码：{{ payinfo.统一社会信用代码 }}</div>
        <div class="text">地址：{{ payinfo.地址 }}</div>
        <div class="text">电话：{{ payinfo.电话 }}</div>
        <div class="word">
          <img src="../assets/wicon_6.png" alt="" />对公支付
        </div>
        <div class="write" @click="invoicing">填写开票信息</div>
        <div class="submit" @click="pay('ruleform')">
          <el-button>提交</el-button>
        </div>
      </div>
      <Footer></Footer>
    </div>
    <el-dialog
      title="填写开票信息"
      center
      :visible.sync="dialogFormVisible"
      width="50%"
    >
      <el-form :model="form" :rules="rules" ref="ruleform">
        <el-form-item label="联系人:" :label-width="formLabelWidth">
          <el-input v-model="form.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="联系人手机:" :label-width="formLabelWidth">
          <el-input v-model="form.phone" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="发票抬头:" :label-width="formLabelWidth">
          <el-input v-model="form.lookup" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="单位税号:" :label-width="formLabelWidth">
          <el-input v-model="form.duty_code" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="注册地址:" :label-width="formLabelWidth">
          <el-input v-model="form.address" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="注册电话:" :label-width="formLabelWidth">
          <el-input v-model="form.tel" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="开户银行:" :label-width="formLabelWidth">
          <el-input v-model="form.bank" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="银行账号:" :label-width="formLabelWidth">
          <el-input v-model="form.bank_code" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="save('ruleform')">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { pay, getData } from "../api/index";
import Header from "../components/header2.vue";
import Footer from "../components/footer2.vue";
import sidebar from "../components/sidebar.vue";
import Menu from "../components/menu.vue";

export default {
  components: {
    Header,
    Footer,
    sidebar,
    Menu,
  },
  data() {
    return {
      token: localStorage.getItem("token"),
      dialogFormVisible: false,
      form: {
        year: new Date().getFullYear(),
        name: "",
        phone: "",
        lookup: "",
        duty_code: "",
        address: "",
        tel: "",
        bank: "",
        bank_code: "",
      },
      paystatus: "",
      pay_file: "",
      payinfo: {},
      payprice: "",
      year: [],
      isfullyear: 1,
      formLabelWidth: "120px",
      fullyearmoney: 0,
      // halfyearmoney: 0,
      checked: true,
      rules: {
        phone: [
          {
            pattern:
              /^1((34[0-8])|(8\d{2})|(([35][0-35-9]|4[579]|66|7[35678]|9[1389])\d{1}))\d{7}$/,
            message: "手机号码格式不正确",
            trigger: "blur",
          },
        ],
        tel: [
          {
            pattern:
              /^1((34[0-8])|(8\d{2})|(([35][0-35-9]|4[579]|66|7[35678]|9[1389])\d{1}))\d{7}$/,
            message: "手机号码格式不正确",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.getData();
    this.yearchange();
  },
  methods: {
    yearchange() {
      // 创建一个新的Date对象
      var date = new Date();
      // 获取当前月份（注意月份从0开始计数）
      var month = date.getMonth() + 1; // 加1是因为月份从0开始计数
      if (month <= 6) {
        this.isfullyear = 1;
        // console.log("当前月份属于上半年");
      } else if (month >= 7 && month <= 12) {
        this.isfullyear = 0;
        // console.log("当前月份属于下半年");
      } else {
        // console.log("无效的输入");
      }
    },
    goto(url) {
      this.$router.push(url);
    },
    invoicing() {
      this.dialogFormVisible = true;
    },
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$message.success("保存成功");
          this.dialogFormVisible = false;
        } else {
          this.$message.error("请按照要求格式填写");
          return false;
        }
      });
    },
    getData() {
      this.Loading(".pay_cont");
      getData({
        uid: localStorage.getItem("uid"),
      }).then((res) => {
        this.isloading.close();
        if (res.data.code == 1) {
          // console.log('1111111111',res.data.data.status)
          this.paystatus = res.data.data.status;
          if (res.data.data.status == 0) {
            this.$message.error("您还未入会，不能缴费");
          }
          // console.log('11111111',res)
          this.payinfo = res.data.data.account;
          this.payprice = res.data.data.price;
          // let yearmoney = res.data.data.data[1].value;
          // let yearfull = res.data.data.data[1].is_year;
          // console.log('yearmoney',yearmoney)
          // if (yearfull == 0) {
          //   this.fullyearmoney = (yearmoney / 1) * 2;
          //   // console.log('this.fullyearmoney',this.fullyearmoney)
          //   this.halfyearmoney = yearmoney;
          // } else {
          //   this.fullyearmoney = yearmoney;
          //   this.halfyearmoney = yearmoney / 2;
          // }
          this.year = res.data.data.year;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    pay(formName) {
      let that = this;
      let date = new Date().getTime();
      that.Loading(".submit");
      pay({
        create_time: date / 1000,
        pay_file: that.pay_file,
        invo_status: that.form.bank_code ? 1 : 0,
        invo_time: "",
        // price: that.isfullyear == 1 ? that.fullyearmoney : that.halfyearmoney,
        // year_type: that.isfullyear == 1 ? "年" : "半年",
        price: that.payprice/1,
        year_type: "年",
        ...that.form,
      }).then((res) => {
        that.isloading.close();
        if (res.data.code == 1) {
          that.$message.success(res.data.msg);
          // console.log(res.data.data.data[0].value)
        } else {
          that.$message.error(res.data.msg);
        }
      });
    },
    handleAvatarSuccess(res) {
      //调用图片上传接口
      // console.log('res',res.data.fullurl)
      this.pay_file = res.data.fullurl;
    },
  },
};
</script>

<style scoped lang="scss">
body {
  padding: 0;
  margin: 0;
}

.el-button--primary {
  width: 30%;
}

.box {
  // height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.sidebar {
  width: 18%;
}

.pay {
  width: 82%;
  background: linear-gradient(
    180deg,
    rgba(171, 198, 246, 0.27) 0%,
    rgba(216, 224, 232, 0.1) 100%
  );
}

.pay_cont {
  height: 580px;
  border-radius: 5px;
  background: #fff;
  margin: 34px 30px 63px 81px;
  padding: 47px 101px 67px 101px;
  position: relative;
}

.pay_cont .title {
  color: #000;
  font-size: 24px;
  font-weight: 500;
}

.pay_cont .but_list {
  display: flex;
  margin-top: 18px;
}

.pay_cont .but_list .but {
  position: relative;
}

.pay_cont .but_list .but .el-button {
  width: 217px;
  height: 63px;
  flex-shrink: 0;
  font-size: 24px;
  font-weight: 500;
  margin-right: 20px;
}

.pay_cont .but_list .but img {
  position: absolute;
  right: 20px;
  bottom: 0;
}

.pay_cont .pay_voucher {
  margin-top: 20px;
  color: #000;
  font-size: 20px;
  font-weight: 500;

  img {
    margin-right: 10px;
    margin-top: -3px;
    vertical-align: middle;
  }
}

.pay_cont .upload_voucher {
  width: 212px;
  height: 50px;
  line-height: 50px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #1890ff;
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 20px;
}

.pay_cont .word {
  margin-top: 20px;
  color: #000;
  font-size: 20px;
  font-weight: 500;
}

.pay_cont .word img {
  vertical-align: middle;
  margin-right: 15px;
  margin-top: -3px;
}

.pay_cont .text {
  color: #000;
  font-size: 18px;
  line-height: 176.523%;
  /* 31.774px */
}

.pay_cont .write {
  width: 212px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  flex-shrink: 0;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border-radius: 5px;
  background: #1890ff;
  margin-top: 20px;
}

.pay_cont .submit {
  text-align: center;
  margin-top: 90px;
}

.pay_cont .submit .el-button {
  width: 334px;
  height: 50px;
  flex-shrink: 0;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  border-radius: 5px;
  background: #1890ff;
}

.el-button2 {
  color: #fff;
  border-color: #c6e2ff;
  background-color: #1890ff;
}

@media only screen and (max-width: 1800px) {
  .pay_cont .title {
    color: #000;
    font-size: 20px;
    font-weight: 500;
  }

  .pay_cont .but_list .but .el-button {
    width: 180px;
    height: 45px;
    flex-shrink: 0;
    font-size: 20px;
    font-weight: 500;
    margin-right: 20px;
  }

  .pay_cont .text {
    color: #000;
    font-size: 16px;
    line-height: 176.523%;
  }

  .pay_cont .word {
    margin-top: 20px;
    color: #000;
    font-size: 18px;
    font-weight: 500;
  }

  .pay_cont .write {
    width: 180px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    flex-shrink: 0;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    background: #1890ff;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 1600px) {
  .pay_cont {
    height: auto;
  }
}

@media only screen and (max-width: 1500px) {
  .pay_cont {
    margin: 30px 30px 0;
    padding: 30px;
  }

  .pay_cont .submit {
    text-align: center;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .box {
    .sidebar {
      width: 0;
    }

    .pay {
      width: 100%;

      .pay_cont {
        .submit {
          width: auto;
        }

        .but_list .but {
          .el-button {
            margin-top: 10px;
            width: auto;
          }
        }
      }

      .pay_cont .but_list .but img {
        // left: 0;
        // position: absolute;
        display: none;
      }
    }
  }

  .pay_cont {
    margin: 10px 10px 0;
    padding: 15px;
  }

  .pay_cont .title {
    color: #000;
    font-size: 16px;
    font-weight: 500;
  }

  .pay_cont .but_list {
    display: flex;
    margin-top: 5px;
  }

  .pay_cont .but_list .but .el-button {
    width: 180px;
    height: 40px;
    flex-shrink: 0;
    font-size: 16px;
    font-weight: 500;
    margin-right: 20px;
    margin-top: 0;
  }

  .pay_cont .pay_voucher {
    margin-top: 10px;
    color: #000;
    font-size: 16px;
    font-weight: 500;
  }

  .pay_cont .upload_voucher {
    width: 170px;
    height: 40px;
    line-height: 40px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #1890ff;
    text-align: center;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    margin-top: 10px;
  }

  .pay_cont .upload_voucher img {
    vertical-align: middle;
    margin-top: -3px;
    margin-right: 5px;
  }

  .pay_cont .word {
    margin-top: 10px;
    color: #000;
    font-size: 16px;
    font-weight: 500;
  }

  .pay_cont .text {
    color: #000;
    font-size: 14px;
    line-height: 176.523%;
  }

  .pay_cont .write {
    width: 170px;
    height: 40px;
    line-height: 40px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #1890ff;
    text-align: center;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    margin-top: 10px;
  }

  .box .pay .pay_cont .submit .el-button {
    width: 200px;
    font-size: 16px;
    height: 40px;
  }

  .el-dialog {
    position: relative;
    margin: 0 auto 50px;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    width: 50%;
    width: 90% !important;
  }

  .el-dialog__title {
    line-height: 24px;
    font-size: 16px;
    color: #303133;
  }

  .el-dialog--center .el-dialog__body {
    text-align: initial;
    padding: 10px;
  }

  .el-form-item {
    margin-bottom: 15px;
  }

  /deep/.el-dialog--center {
    width: 90% !important;
  }
}
</style>